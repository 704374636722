/**
 * Single DataLayer - Secondary part
 * Placed in the footer, pushes data to sdl object
 * Primary part (singleDL-primary.js) is placed in the <head>
 * @version 1.0.0
 * @author Vendula Frýzlová <vendula.fryzlova@avast.com>, Jaroslav Slaby <jaroslav.slaby@avast.com>
 * @copyright Avast Software s.r.o &copy;2022
 */

(function sdlSecondary() {
	'use strict';
	if (window.sdl !== undefined) {
		try {
			var sdlSecondaryObj = {
				// After click on a button, create object and push to sdl
				buttons: function () {
					document.addEventListener('click', function (e) {

						// DL buttons
						if (e.target.closest('a[data-role="download-link"]')) {
							var button = e.target.closest('a[data-role="download-link"]');
							if (button) {
								sdl.push({
									user: {
										download: {
											products: [{
												campaign: sdlSecondaryObj.checkAttribute(button, 'data-campaign'),
												campaignMarker: sdlSecondaryObj.checkAttribute(button, 'data-campaign-marker'),
												sku: sdlSecondaryObj.checkAttribute(button, 'data-download-name'),
												maintenance: 0,
												seats: 1,
												quantity: 1,
												currencyCode: 'USD',
												category: 'Consumer',
												price: 0,
												tax: 0,
												brand: 'Avast',
												link: sdlSecondaryObj.removeUnwantedParamsFromLink(button.href),
												offerType: 'download',
												localPrice: 0,
												localTax: 0
											}]
										}
									},
									event: 'user.download.products'
								})
							}
							// Download popup
							if (document.querySelector('[data-behavior="downloadPopup"]') !== undefined) {
								var popup = document.querySelector('[data-behavior="downloadPopup"]')
								sdl.push({
									system: {
										modal: {
											id: popup.id,
											component: sdlSecondaryObj.checkAttribute(popup, 'data-cmp-name'),
											path: '/' + sdlObj.screen.locale + '/' + sdlSecondaryObj.checkAttribute(popup, 'data-cmp-name')
										}
									},
									event: 'system.modal'
								});

								var URLParams = new URLSearchParams(window.location.search);
								sdl.push({
									event: 'screen',
									screen: {
										location: {
											url: window.location.search ? sdlSecondaryObj.removeUnwantedParamsFromUrl(URLParams) : window.location.host + window.location.pathname,
											woParams: window.location.host + window.location.pathname,
											protocol: window.location.protocol.replace(':', ''),
											path: '/' + sdlObj.screen.locale + '/' + sdlObj.screen.path,
											hostname: sdlObj.screen.hostname,
											hash: window.location.hash,
											params: window.location.search ? Object.fromEntries(URLParams) : ''
										},
										locale: sdlObj.screen.locale,
										screenCategory: sdlObj.screen.screenCategory,
										title: document.title,
										type: 'modal',
										name: sdlObj.screen.name,
										section: popup.id,
										lineOfBusiness: sdlObj.screen.lineOfBusiness,
										screenId: sdlObj.screen.screenId
									}
								});
							}

							// Buy buttons
						} else if (e.target.closest('a[data-role="cart-link"]')) {
							var button = e.target.closest('a[data-role="cart-link"]'),
								multipleProducts = button.getAttribute('data-product-id').includes('|');

							if (button) {

								// Multiple products in a button (Help me choose on SMB)
								if (multipleProducts) {
									var productsArray = [],
										attributesArray = [],
										productsAmount = button.getAttribute('data-product-id').split('|').length

									// Create array of button data attributes for each product
									attributesArray.push(
										button.getAttribute('data-product-id').split('|'),		// 0
										button.getAttribute('data-quantity').split('|'),		// 1
										button.getAttribute('data-seats').split('|'),			// 2
										button.getAttribute('data-maintenance').split('|'),		// 3
										button.getAttribute('data-campaign').split('|'),		// 4
										button.getAttribute('data-campaign-marker').split('|'),	// 5
										button.getAttribute('data-price').split('|'),			// 6
										button.getAttribute('data-product-category').split('|'),// 7
										button.getAttribute('data-currency').split('|')			// 8
									)

									var i = 0;
									attributesArray.forEach(function () {
										// Check products amount
										while (i < productsAmount) {
											// take values from each data attribute and push them into array of product objects
											productsArray.push({
												campaign: attributesArray[4][i],
												campaignMarker: attributesArray[5][i],
												sku: attributesArray[0][i],
												maintenance: Number(attributesArray[3][i]),
												seats: Number(attributesArray[2][i]),
												quantity: Number(attributesArray[1][i]),
												currencyCode: 'USD',
												category: attributesArray[7][i],
												brand: 'Avast',
												link: sdlSecondaryObj.removeUnwantedParamsFromLink(button.href),
												offerType: 'buy',
												localCurrencyCode: attributesArray[8][i],
												localPrice: Number(attributesArray[6][i])
											})
											i++
										}
									})

									// Push the product array to 'sdl'
									sdl.push({
										user: {
											buy: { products: productsArray }
										},
										event: 'user.buy.products'
									})

									// One product in a buy button
								} else {
									sdl.push({
										user: {
											buy: {
												products: [{
													campaign: sdlSecondaryObj.checkAttribute(button, 'data-campaign'),
													campaignMarker: sdlSecondaryObj.checkAttribute(button, 'data-campaign-marker'),
													sku: sdlSecondaryObj.checkAttribute(button, 'data-product-id'),
													maintenance: Number(sdlSecondaryObj.checkAttribute(button, 'data-maintenance')),
													seats: Number(sdlSecondaryObj.checkAttribute(button, 'data-seats')),
													quantity: Number(button.hasAttribute('data-quantity') ? button.getAttribute('data-quantity') : '1'),
													currencyCode: 'USD',
													category: sdlSecondaryObj.checkAttribute(button, 'data-product-category'),
													brand: 'Avast',
													link: sdlSecondaryObj.removeUnwantedParamsFromLink(button.href),
													offerType: 'buy',
													localCurrencyCode: sdlSecondaryObj.checkAttribute(button, 'data-currency'),
													localPrice: Number(button.hasAttribute('data-price') ? button.getAttribute('data-price') : '0')
												}]
											}
										},
										event: 'user.buy.products'
									})
								}
							}

							// Media buttons
						} else if (e.target.closest('a[href*=".pdf"], a[data-role="media"]')) {
							var button = e.target.closest('a[href*=".pdf"], a[data-role="media"]');
							if (button) {
								sdl.push({
									user: {
										download: {
											media: {
												type: 'media',
												name: button.href.match(/.*\/(.*?\..*)/)[1].split('?')[0],
												url: sdlSecondaryObj.removeUnwantedParamsFromLink(window.location.href)
											}
										}
									},
									event: 'user.download.media'
								})
							}
							// Function buttons - Close
						} else if (e.target.closest('button.modal-close')) {
							var button = e.target.closest('button.modal-close'),
								modal = button.closest('.modal');
							if (button) {
								sdl.push({
									user: {
										click: {
											element: {
												actionType: 'close',
												component: 'cmp-modal',
												path: '/' + sdlObj.screen.locale + '/cmp-modal',
												id: modal.id,
											}
										}
									},
									event: 'user.click.element'
								})
							}
							// Function buttons - Close DTYP modal
						} else if (e.target.closest('.js-dtp-close')) {
							var button = e.target.closest('.js-dtp-close'),
								modal = button.closest('.js-popup-dtp');
							if (button) {
								sdl.push({
									user: {
										click: {
											element: {
												actionType: 'close',
												component: 'cmp-dtyp-popup',
												path: '/' + sdlObj.screen.locale + '/cmp-dtyp-popup',
												id: modal.id,
											}
										}
									},
									event: 'user.click.element'
								})
							}
							// Other link buttons
						} else if (e.target.closest('a[data-role="promo-link"], a[data-role="cta-link"]')) {
							var button = e.target.closest('a[data-role="promo-link"], a[data-role="cta-link"]');
							if (button) {
								sdl.push({
									user: {
										click: {
											link: {
												clickUrl: sdlSecondaryObj.checkAttribute(button, 'href'),
												url: sdlSecondaryObj.removeUnwantedParamsFromLink(window.location.href),
												type: sdlSecondaryObj.checkAttribute(button, 'data-role'),
												text: e.target.closest('span') ? e.target.closest('span').textContent : ''
											}
										}
									},
									event: 'user.click.link'
								})
							}
							// Submit form buttons
						} else if (e.target.closest('form')) {
							var form = e.target.closest('form');
							if (form) {
								sdl.push({
									user: {
										send: {
											form: {
												id: sdlSecondaryObj.checkAttribute(form, 'id'),
												name: sdlSecondaryObj.checkAttribute(form, 'action'),
												url: sdlSecondaryObj.removeUnwantedParamsFromLink(window.location.href)
											}
										}
									},
									event: 'user.send.form'
								})
							}
						}
					})
				},
				// Modal
				modal: function () {
					var modals = document.querySelectorAll('.modal')
					for (var i = 0; i < modals.length; i++) {
						var modal = modals[i];

						modal.addEventListener('shown.bs.modal', function (e) {
							modal = e.target;
							sdl.push({
								system: {
									modal: {
										id: modal.id,
										component: sdlSecondaryObj.checkAttribute(modal, 'data-cmp-name'),
										path: '/' + sdlObj.screen.locale + '/' + sdlSecondaryObj.checkAttribute(modal, 'data-cmp-name')
									}
								},
								event: 'system.modal'
							});

							var URLParams = new URLSearchParams(window.location.search);
							sdl.push({
								event: 'screen',
								screen: {
									location: {
										url: window.location.search ? sdlSecondaryObj.removeUnwantedParamsFromUrl(URLParams) : window.location.host + window.location.pathname,
										woParams: window.location.host + window.location.pathname,
										protocol: window.location.protocol.replace(':', ''),
										path: '/' + sdlObj.screen.locale + '/' + sdlObj.screen.path,
										hostname: sdlObj.screen.hostname,
										hash: window.location.hash,
										params: window.location.search ? Object.fromEntries(URLParams) : ''
									},
									locale: sdlObj.screen.locale,
									screenCategory: sdlObj.screen.screenCategory,
									title: document.title,
									type: 'modal',
									name: sdlObj.screen.name,
									section: modal.id,
									lineOfBusiness: sdlObj.screen.lineOfBusiness,
									screenId: sdlObj.screen.screenId
								}
							});
						})
					}
				},
				// Check if element has specific data attribute and get its value
				checkAttribute: function (clickedElement, attributeName) {
					return clickedElement.hasAttribute(attributeName) ? clickedElement.getAttribute(attributeName) : '';
				},

				// Remove unwanted URL parameters from a link
				removeUnwantedParamsFromLink: function (href) {
					var hash = window.location.hash,
						url = new URL(href),
						URLParams = new URLSearchParams(url.search),
						unwantedParams = ['ASWPARAM', 'aswparam', '_ga'];

					url.search = ''
					var btnHrefWithoutParams = url.toString().split('#')[0]

					for (var i = 0; i < unwantedParams.length; i++) {
						URLParams.delete(unwantedParams[i])
					}
					var URLWithoutUnwantedParams = btnHrefWithoutParams + '?' + URLParams.toString() + hash
					return URLWithoutUnwantedParams
				},
				// Remove unwanted URL parameters from URL
				removeUnwantedParamsFromUrl: function (URLParams) {
					var unwantedParams = ['ASWPARAM', 'aswparam', '_ga'];
					for (var i = 0; i < unwantedParams.length; i++) {
						URLParams.delete(unwantedParams[i])
					}
					var URLWithoutUnwantedParams = window.location.host + window.location.pathname + '?' + URLParams.toString()
					return URLWithoutUnwantedParams
				}
			}

			// Custom event from 'otBannerSdk.js' script. It watches changes in cookie consent
			window.addEventListener('OneTrustGroupsUpdated', function () {
				// on change push cookie data to sdl datalayer

				// uncomment and remove fix when apple develops a working browser and not that shit safari (safari doesn't support ?<=, also they don't know what try-catch is)
				//if (/OptanonConsent=.*?(consentId=(.*?)(?=&))/.test(document.cookie) && /(?<=NotLandingPage&groups=|groups=).*?(?=&|;|$)/.test(document.cookie)) {
				if (/OptanonConsent=.*?(consentId=(.*?)(?=&))/.test(document.cookie) && /(?:NotLandingPage&groups=|groups=).*?(?=&|;|$)/.test(document.cookie)) { // safari fix
					sdl.push({
						client: {
							cmpId: document.cookie.match(/OptanonConsent=.*?(consentId=(.*?)(?=&))/)[2]
						},
						//consentGroups: document.cookie.match(/(?<=NotLandingPage&groups=|groups=).*?(?=&|;|$)/)[0].replace(/%3A/g, ':').replace(/%/g, ',').replace(/2C/g, ' '),
						consentGroups: document.cookie.match(/(?:NotLandingPage&groups=|groups=).*?(?=&|;|$)/)[0].replace(/NotLandingPage&groups=|groups=/, '').replace(/%3A/g, ':').replace(/%/g, ',').replace(/2C/g, ' '), // safari fix
						user: {
							update: {
								consent: {}
							}
						},
						event: "user.update.consent"
					})
				}
			})

			sdlSecondaryObj.buttons();
			sdlSecondaryObj.modal();

			return sdlSecondaryObj;

		} catch (error) {
			console.error(error);
			sdl.push({
				system: {
					error: {
						type: error.name,
						message: error.message,
					}
				},
				event: 'system.error'
			})
		}
	}
})();
